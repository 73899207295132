//
//
//
//
//
//

export default {
  name: 'Frequency',
  computed: {
    src() {
      return `${process.env.BASE_URL}/images/branding/frequency.svg`
    },
  },
}
