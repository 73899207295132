//
//
//
//
//
//
//
//

export default {
  name: 'SocialMedia',
  props: {
    theme: {
      type: String,
      default: 'default',
    },
  },
  data: () => ({
    sm: [
      {
        name: 'facebook',
        theme: '#1877F2',
        link: 'https://www.facebook.com/fmderana',
      },
      {
        name: 'twitter',
        theme: '#1DA1F2',
        link: 'https://twitter.com/FMDeranaRadio',
      },
      {
        name: 'youtube',
        theme: '#FF0000',
        link: 'https://www.youtube.com/channel/UCOPPH1_whpThgY6e7r3qg-w',
      },
      {
        name: 'instagram',
        theme: '#E4405F',
        link: 'https://instagram.com/fmderana?igshid=re6mkojyj851',
      },
    ],
  }),
}
