//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Aplayer from 'vue-aplayer'

export default {
  name: 'AudioPlayer',
  components: {
    Aplayer,
  },
  props: {
    left: {
      type: Number,
      default: 350,
    },
  },
  computed: {
    ...mapState(['playlist', 'nowPlaying']),
    playerStyles() {
      return `left: ${this.left}px;`
    },
    playerClass() {
      if (this.playlist.length !== 0) {
        return 'audio-player player-visible'
      } else {
        return 'audio-player player-hidden'
      }
    },
    // playlistFormatted() {
    //   let playlistFormatted = [];
    //   const defaultTrack = {
    //     title: 'No track available',
    //     artist: '',
    //     src: '',
    //     pic: `${process.env.LOCAL_ASSETS_IMAGE_PATH}/branding/fm-derana-emblem.png`,
    //   }

    //   if(this.playlist.length === 0) {
    //   }
    // }
  },
  watch: {
    playlist() {
      this.playUpdatedPlaylist()
    },
  },
  methods: {
    playUpdatedPlaylist() {
      if (this.$refs.player) {
        this.$refs.player.shuffledList = this.playlist
        this.$refs.player.thenPlay()
      }
    },
  },
}
