//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import SocialMedia from '~/components/SocialMedia'
import DeranaBrands from '~/components/Footer/DeranaBrands'

export default {
  name: 'Footer',
  components: {
    SocialMedia,
    DeranaBrands,
  },
  computed: {
    ...mapState(['playlist', 'nowPlaying']),
    logoSrc() {
      return `${process.env.BASE_URL}/images/branding/fm-derana-logo.svg`
    },
    frqSrc() {
      return `${process.env.BASE_URL}/images/branding/frequency.svg`
    },
    playerClass() {
      if (this.playlist.length !== 0) {
        return 'player-visible'
      } else {
        return 'player-hidden'
      }
    },
  },
}
