//
//
//
//
//
//
//
//

import { mdiCircle } from '@mdi/js'

export default {
  name: 'OnAir',
  data: () => ({
    mdiCircle,
  }),
  methods: {
    openStream() {
      window.open(
        `${process.env.FM_LIVE_STREAM_EXTERNAL}`,
        'welcome',
        'width=450,height=650'
      )
    },
  },
}
