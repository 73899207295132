//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'SiteSearch',
  data: () => ({
    keyword: '',
    searching: false,
    showHint: false,
    mdiMagnify,
  }),
  computed: {
    ...mapState(['searchResults']),
  },
  methods: {
    handleSearch() {
      const term = this.keyword.replace(/(<([^>]+)>)/gi, '')
      if (term !== '' && term.length > 3) {
        this.searching = true
        this.showHint = false
        this.search(term)
      } else {
        this.searching = false
        this.showHint = true
      }
    },
    async search(term) {
      try {
        let result = await this.$axios.$get(`${process.env.API_URL}`, {
          // let result = await this.$axios.$get(`http://fm-derana-api.test`, {
          params: {
            search: term,
          },
        })
        result = result.data
        this.searching = false
        this.$store.commit('UPDATE_SEARCH_RESULTS', result)
        this.$router.push({
          path: '/search',
          query: {
            keyword: this.keyword,
          },
        })
      } catch (err) {
        // eslint-disable-next-line
        console.error('search error', err)
      }
    },
  },
}
