//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DeranaBrands',
  computed: {
    tvSrc() {
      return `${process.env.BASE_URL}/images/derana-brands/TV_Derana_Logo.png`
    },
    adaSrc() {
      return `${process.env.BASE_URL}/images/derana-brands/Ada_Derana_2019.png`
    },
    etunesSrc() {
      return `${process.env.BASE_URL}/images/derana-brands/etunes-logo.jpg`
    },
  },
}
