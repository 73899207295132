//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Header from '~/components/Header'
import Footer from '~/components/Footer'
import Sidebar from '~/components/Sidebar'
import Logo from '~/components/Sidebar/Logo'
import AudioPlayer from '~/components/AudioPlayer'

export default {
  components: {
    Footer,
    Sidebar,
    Header,
    Logo,
    AudioPlayer,
  },
  data() {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      width: 0,
    }
  },
  computed: {
    sidebarWidth() {
      let width = 350

      if (this.$mq) {
        switch (this.$mq) {
          case 'xl':
            width = 350
            break
          case 'lg':
            width = 275
            break
          case 'md':
            width = 275
            break
          case 'sm':
            width = 300
            break
          case 'xs':
            width = 250
            break
          default:
            break
        }
      }

      return width
    },
    playerOffset() {
      let width = 350

      if (this.$mq) {
        switch (this.$mq) {
          case 'xl':
            width = 350
            break
          case 'lg':
            width = 275
            break
          case 'md':
            width = 0
            break
          case 'sm':
            width = 0
            break
          case 'xs':
            width = 0
            break
          default:
            break
        }
      }

      return width
    },
  },
}
