//
//
//
//
//
//
//
//
//
//

import SiteSearch from '~/components/Header/SiteSearch'
import SocialMedia from '~/components/SocialMedia'

export default {
  name: 'Header',
  components: {
    SiteSearch,
    SocialMedia,
  },
  computed: {
    frqSrc() {
      return `${process.env.BASE_URL}/images/branding/frequency.svg`
    },
  },
}
