import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=dadb23a8&scoped=true&lang=pug&"
import script from "./Sidebar.vue?vue&type=script&lang=js&"
export * from "./Sidebar.vue?vue&type=script&lang=js&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=dadb23a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dadb23a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/home/hasi/DEV/FG/fm-derana/components/Logo.vue').default,Header: require('/home/hasi/DEV/FG/fm-derana/components/Header.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VSpacer})
