//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import LivestreamPlayer from '~/components/Sidebar/Livestream/LivestreamPlayer'

export default {
  name: 'Livestream',
  components: {
    LivestreamPlayer,
  },
  data: () => ({
    stream: `${process.env.FM_LIVE_STREAM}`,
  }),
  computed: {
    ...mapState(['currentProgram']),
    currentProgramFormatted() {
      const program = {}
      let programImage = 'fm-derana-emblem.png'
      let programTitle = 'No Program Information'
      let startTime = ''
      let endTime = ''

      if (this.currentProgram.length > 0) {
        programImage = this.currentProgram[0].program_image
        programTitle = this.currentProgram[0].program
        startTime = this.currentProgram[0].up_start_time
        endTime = this.currentProgram[0].up_end_time
      }

      program.title = programTitle
      program.image = programImage
      program.start = startTime
      program.end = endTime

      return program
    },
  },
  mounted() {
    this.getCurrentProgram()
    setTimeout(() => {
      this.pollCurrentProgramData()
    }, 5000)
  },
  methods: {
    ...mapActions(['getCurrentProgram']),
    pollCurrentProgramData() {
      // const self = this
      this.getData = setInterval(() => {
        this.$store.dispatch('getCurrentProgram')
      }, 60000)
    },
  },
}
