import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=6e8c850d&scoped=true&lang=pug&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=6e8c850d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e8c850d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SocialMedia: require('/home/hasi/DEV/FG/fm-derana/components/SocialMedia.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VSpacer})
