//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '~/components/Sidebar/Logo'
import Navigation from '~/components/Sidebar/Navigation'
import Frequency from '~/components/Sidebar/Frequency'
import Livestream from '~/components/Sidebar/Livestream'
import OnAir from '~/components/Sidebar/OnAir'
import UploadNewRelease from '~/components/Sidebar/UploadNewRelease'

export default {
  name: 'Sidebar',
  components: {
    Logo,
    Navigation,
    Frequency,
    Livestream,
    OnAir,
    UploadNewRelease,
  },
  computed: {
    src() {
      return `${process.env.BASE_URL}/images/branding/tagline-black.svg`
    },
  },
}
