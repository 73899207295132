//
//
//
//
//
//
//
//

export default {
  data: () => ({
    items: [
      { link: '/', text: 'Home' },
      { link: '/programs', text: 'Programs' },
      { link: '/contact', text: 'Contact' },
    ],
  }),
}
